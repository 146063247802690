import { MenuDivider, MenuItem } from '@szhsin/react-menu';
import { AnimatePresence, motion } from 'framer-motion';
import { nanoid } from 'nanoid';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as HorizontalDotsIcon } from '../../assets/icons/HorizontalDots.svg';
import { getFormattedDateV2, getFormattedNumber } from '../../helpers/utils';
import { useUpdateInvoiceIntegration } from '../../hooks/useBills';
import { useFilteredMenuList } from '../../hooks/useFilteredMenuList';
import { cn, getContactName } from '../../lib/utils';
import { downloadFile } from '../../store/features/downloadSlice';
import { addToast } from '../../store/features/toastSlice';
import BillInvoiceItems from '../accounts/account-detail-container/details-container/account-bills/bill-invoice-items';
import FlexBox from '../common/flex-box/flex';
import IconContainer from '../common/icon-container';
import Label from '../common/label';
import MenuV2 from '../common/menu/menu-v2';
import Status from '../common/status';
import ViewFile from '../view-file/view-file';

// This ItemMenu component is also using in account-bills.js file
export const ItemMenu = ({
  portal = false,
  menuList = [],
  billIntegrationId,
  integrations = [],
  onIntegrationClick = () => {},
  // onSendToCustomerClick = () => {},
}) => {
  const isIntegrationsAvailable = integrations.length > 0;

  return (
    <MenuV2
      portal={portal}
      menuList={menuList}
      Icon={HorizontalDotsIcon}
      menuWrapperClassname="flex"
      ExtraOptions={
        !billIntegrationId ? (
          <Fragment>
            {menuList.length > 0 && <MenuDivider />}
            <div className="flex-col rounded-md overflow-hidden">
              {isIntegrationsAvailable && (
                <div className="flex-col items-center">
                  <li className="inter-400-text px-4 py-3">Export to</li>
                  {integrations.map(integration => (
                    <Fragment key={integration.id}>
                      <MenuDivider />
                      <MenuItem
                        className="inter-400-text pl-8 pr-4 py-3 cursor hover:bg-neutral-50"
                        onClick={() => onIntegrationClick(integration)}>
                        {integration.name}
                      </MenuItem>
                    </Fragment>
                  ))}
                </div>
              )}
              {/* {isIntegrationsAvailable && <MenuDivider />}
            <li className="inter-400-text px-4 py-2 cursor hover:bg-neutral-50" onClick={onSendToCustomerClick}>Send to Customer</li> */}
            </div>
          </Fragment>
        ) : null
      }
    />
  );
};

const BillListItem = ({ bill = {}, showBorderTop = false, createInvoiceIntegrations = [] }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showViewFile, setShowViewFile] = useState(false);
  const [showInvoiceItems, setShowInvoiceItems] = useState(false);

  const {
    id: billId,
    account,
    invoice,
    start_date,
    end_date,
    due_date,
    issued_date,
    status,
    code,
    total_amount,
    integration,
    media,
  } = bill || {};
  const { account_number, contact } = account || {};
  const { phone, email } = contact || {};
  const { id: integrationId, name: integrationName, icon } = integration || {};
  const { active: integrationIcon } = icon || {};
  const { url: mediaUrl } = media || {};
  const { id: invoiceId } = invoice || {};

  const contactName = getContactName(contact);
  const billingPeriodStart = getFormattedDateV2({ dateInUnix: start_date, format: 'Do of MMMM' });
  const billingPeriodEnd = getFormattedDateV2({ dateInUnix: end_date, format: 'Do of MMMM' });

  const onViewFile = file => {
    setShowViewFile(file);
  };

  const onDownloadFile = async file => {
    const { media } = file || {};
    if (media?.url) {
      dispatch(downloadFile({ url: media?.url, filename: file?.code })).catch(() => {
        dispatch(addToast({ error: true, text: t('FAILED_TO_DOWNLOAD_FILE'), id: nanoid() }));
      });
    } else {
      dispatch(addToast({ error: true, text: t('THERE_IS_NO_MEDIA_ATTECHED'), id: nanoid() }));
    }
  };

  const invoiceIntegrationMutation = useUpdateInvoiceIntegration({ invoice_id: invoiceId });

  const onIntegrationClick = integration => {
    invoiceIntegrationMutation.mutate({ integration_id: integration.id, integration });
  };

  return (
    <div>
      <motion.div
        initial={{ opacity: 0.3, y: 10 }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
        key={billId}
        className={cn(
          'px-6 py-4 min-h-[72px] grid grid-cols-bills-list-grid gap-x-4 cursor hover:bg-neutral-50',
          showBorderTop && 'border-top',
        )}>
        <FlexBox className="items-start">
          <Label className="inter-400-text">{code}</Label>
        </FlexBox>
        <FlexBox className="flex-col items-start gap-y-1 overflow-hidden">
          <Label className="inter-400-text">{contactName}</Label>
          <Label className="inter-400-text natural-500-text font-12">Account number: {account_number}</Label>
          <Label className="inter-400-text natural-500-text font-12">{phone || t('NO_TYPE', { type: 'phone' })}</Label>
          <Label className="inter-400-text natural-500-text font-12">{email || t('NO_TYPE', { type: 'email' })}</Label>
        </FlexBox>
        <FlexBox className="flex-col items-start">
          <Label className="flex whitespace-nowrap inter-400-text">{billingPeriodStart} - </Label>
          <Label className="flex whitespace-nowrap inter-400-text">{billingPeriodEnd}</Label>
        </FlexBox>
        <FlexBox className="items-start">
          <Label className="inter-400-text">{getFormattedDateV2({ dateInUnix: issued_date }) || '-'}</Label>
        </FlexBox>
        <FlexBox className="items-start">
          <Label className="inter-400-text">{getFormattedDateV2({ dateInUnix: due_date }) || '-'}</Label>
        </FlexBox>
        <FlexBox className="items-start">
          <Label className="inter-400-text">{getFormattedNumber(total_amount)}</Label>
        </FlexBox>
        <FlexBox className="items-start gap-x-3">
          <Status status={status} />
          {integrationId && integrationIcon && (
            <FlexBox className="flex items-center gap-x-2">
              <img src={integrationIcon} alt={integrationName} className="w-8 aspect-auto" />
            </FlexBox>
          )}
        </FlexBox>
        <div className="flex items-start justify-end">
          <IconContainer
            Icon={ArrowRightIcon}
            iconContainerClassname="cursor"
            iconClassName={cn('transition-all', showInvoiceItems ? 'rotate-270' : 'rotate-90')}
            onClick={() => setShowInvoiceItems(!showInvoiceItems)}
            backgroundColor="transparent"
          />
        </div>
        <ItemMenu
          portal={true}
          integrations={createInvoiceIntegrations}
          billIntegrationId={integrationId}
          onIntegrationClick={onIntegrationClick}
          menuList={useFilteredMenuList({
            menuList: [
              {
                name: 'View PDF',
                otherPermissionFunc: () => mediaUrl,
                onClick: () => onViewFile(bill),
              },
            ],
          })}
        />
      </motion.div>
      <AnimatePresence>
        {showInvoiceItems && (
          <motion.div
            className="flex-col w-full"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}>
            <BillInvoiceItems invoice={invoice} />
          </motion.div>
        )}
      </AnimatePresence>
      {showViewFile && (
        <ViewFile
          close={() => setShowViewFile(null)}
          file={showViewFile}
          selectedDoc={showViewFile}
          downloadPdf={onDownloadFile}
        />
      )}
    </div>
  );
};

export default BillListItem;
