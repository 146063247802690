import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getErrorDescription } from '../helpers/utils';
import { addToast } from '../store/features/toastSlice';

export const useError = props => {
  const {
    getErrorTextFunc = null,
    toastMeta = {},
    showToast = true,
    error = null,
    default_message = 'Something went wrong!',
  } = props || {};
  const dispatch = useDispatch();

  const getErrorText = ({ error = {}, default_message }) => {
    let errorText = getErrorTextFunc ? getErrorTextFunc(error) : getErrorDescription(error);
    const tooLongErrorText = errorText && errorText.length > 100;
    if (tooLongErrorText) {
      errorText = default_message;
      return errorText;
    }
    return errorText || default_message;
  };

  const showErrorToast = ({ additionalMeta = {}, default_message = '', error = null }) => {
    const errorText = getErrorText({ error, default_message });
    dispatch(addToast({ ...toastMeta, ...additionalMeta, text: errorText, error: true, id: nanoid() }));
  };

  useEffect(() => {
    if (error && showToast) {
      const errorText = getErrorText({ error, default_message });
      dispatch(addToast({ ...toastMeta, text: errorText, error: true, id: nanoid() }));
    }
  }, [error, showToast]);

  return { error, showErrorToast };
};
