import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { ReactComponent as ArrowRightIcon } from '../../../../../assets/icons/arrow-right.svg';
import { getFormattedDateV2, getFormattedNumber } from '../../../../../helpers/utils';
import { useActionIntegrations, useBills, useUpdateInvoiceIntegration } from '../../../../../hooks/useBills';
import { useError } from '../../../../../hooks/useError';
import { useFilteredMenuList } from '../../../../../hooks/useFilteredMenuList';
import { cn } from '../../../../../lib/utils';
import { downloadFile } from '../../../../../store/features/downloadSlice';
import FlexBox from '../../../../common/flex-box/flex';
import IconContainer from '../../../../common/icon-container';
import InfiniteScrollV2 from '../../../../common/infinite-scroll-v2';
import Label from '../../../../common/label';
import Status from '../../../../common/status';
import TableHeader from '../../../../common/table-header';
import { ItemMenu } from '../../../../invoices/bill-list-item';
import ViewFile from '../../../../view-file/view-file';
import BillInvoiceItems from './bill-invoice-items';

const billHeaders = [
  { name: 'NUMBER', key: 'NUMBER' },
  { name: 'BILL PERIOD', key: 'BILL_PERIOD' },
  { name: 'ISSUED DATE', key: 'ISSUED_DATE' },
  { name: 'DUE DATE', key: 'DUE_DATE' },
  { name: 'AMOUNT', key: 'AMOUNT' },
  { name: 'STATUS', key: 'STATUS' },
  { name: '', key: 'BILL_INVOICE' },
  { name: '', key: 'MENU' },
];

const BillContent = ({ bill, showBorderTop = false, createInvoiceIntegrations }) => {
  const errorToast = useError();

  const [showInvoiceItems, setShowInvoiceItems] = useState(false);
  const [showViewFile, setShowViewFile] = useState(null);

  const { code, start_date, end_date, issued_date, due_date, status, total_amount, integration, invoice, media } =
    bill || {};
  const { id: integrationId, name: integrationName, icon } = integration || {};
  const { active: integrationActiveIcon } = icon || {};
  const { id: invoiceId } = invoice || {};
  const { url: mediaUrl } = media || {};

  const billingPeriodStart = getFormattedDateV2({ dateInUnix: start_date, format: 'Do of MMMM' });
  const billingPeriodEnd = getFormattedDateV2({ dateInUnix: end_date, format: 'Do of MMMM' });

  const invoiceIntegrationMutation = useUpdateInvoiceIntegration({ invoice_id: invoiceId });

  const onIntegrationClick = integration => {
    invoiceIntegrationMutation.mutate({ integration_id: integration.id, integration });
  };

  const onViewFile = file => {
    setShowViewFile(file);
  };

  const onDownloadFile = async file => {
    const { media } = file || {};
    if (media?.url) {
      dispatch(downloadFile({ url: media?.url, filename: file?.code })).catch(error => {
        errorToast.showErrorToast({ error, default_message: t('FAILED_TO_DOWNLOAD_FILE') });
      });
    } else {
      errorToast.showErrorToast({ default_message: t('THERE_IS_NO_MEDIA_ATTECHED') });
    }
  };

  return (
    <div>
      <div
        className={cn(
          'px-4 py-4 grid grid-cols-account-bills-grid gap-x-4 cursor hover:bg-neutral-50',
          showBorderTop && 'border-top',
        )}>
        <Label className="flex items-start inter-400-text one-line">{code}</Label>
        <Label className="flex-col items-start inter-400-text">
          <span className="flex whitespace-nowrap">{billingPeriodStart} - </span>
          <span className="flex whitespace-nowrap">{billingPeriodEnd}</span>
        </Label>
        <Label className="flex items-start inter-400-text">
          {getFormattedDateV2({ dateInUnix: issued_date }) || '-'}
        </Label>
        <Label className="flex items-start inter-400-text">{getFormattedDateV2({ dateInUnix: due_date }) || '-'}</Label>
        <Label className="flex items-start inter-400-text">{getFormattedNumber(total_amount)}</Label>
        <Label className="flex items-start inter-400-text gap-x-3">
          <Status status={status} />
          {integrationId && integrationActiveIcon && (
            <FlexBox className="flex items-center gap-x-2">
              <img src={integrationActiveIcon} alt={integrationName} className="w-8 aspect-auto" />
            </FlexBox>
          )}
        </Label>
        <div className="flex items-center justify-end">
          <IconContainer
            Icon={ArrowRightIcon}
            iconContainerClassname="cursor"
            iconClassName={cn('transition-all', showInvoiceItems ? 'rotate-270' : 'rotate-90')}
            onClick={() => setShowInvoiceItems(!showInvoiceItems)}
            backgroundColor="transparent"
          />
        </div>
        <div className="flex items-center justify-end">
          <ItemMenu
            portal={true}
            integrations={createInvoiceIntegrations}
            billIntegrationId={integrationId}
            onIntegrationClick={onIntegrationClick}
            menuList={useFilteredMenuList({
              menuList: [
                {
                  name: 'View PDF',
                  otherPermissionFunc: () => mediaUrl,
                  onClick: () => onViewFile(bill),
                },
              ],
            })}
          />
        </div>
      </div>
      <AnimatePresence>
        {showInvoiceItems && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}>
            <BillInvoiceItems invoice={invoice} />
          </motion.div>
        )}
      </AnimatePresence>
      {showViewFile && (
        <ViewFile
          close={() => setShowViewFile(null)}
          file={showViewFile}
          selectedDoc={showViewFile}
          downloadPdf={onDownloadFile}
        />
      )}
    </div>
  );
};

const AccountBills = () => {
  const { t } = useTranslation();
  const { account_id } = useParams();

  const {
    data: billsList,
    isLoading,
    hasNextPage,
    fetchNextPage,
    error,
  } = useBills({
    params: { account_id, page: 0 },
  });

  const { data: createInvoiceIntegrations } = useActionIntegrations({ action_type: 'INVOICE_CREATE' });

  useError({ error, default_message: t('ERROR_WHILE_FETCHING_BILLS') });

  const isDataEmpty = !billsList || (billsList && billsList.length === 0);

  return isLoading ? (
    <Skeleton containerClassName="h-full tracking-[1px]" height={'100%'} />
  ) : (
    <div className="flex-col flex border rounded-xl overflow-x-auto">
      <TableHeader
        headers={billHeaders}
        headerClassName="px-4 bg-natural-50 min-w-full w-fit h-[52px] grid grid-cols-account-bills-grid gap-x-4"
      />
      {isDataEmpty && (
        <div className="flex items-center justify-center h-14 inter-400-text">{t('NO_TYPE', { type: 'bills' })}</div>
      )}
      {!isDataEmpty && (
        <InfiniteScrollV2
          infiniteScrollClassName="min-w-full w-fit"
          hasMore={hasNextPage}
          fetchMoreData={fetchNextPage}>
          {billsList.map((bill, index) => (
            <BillContent
              key={bill.id}
              bill={bill}
              showBorderTop={index !== 0}
              createInvoiceIntegrations={createInvoiceIntegrations}
            />
          ))}
        </InfiniteScrollV2>
      )}
    </div>
  );
};

export default AccountBills;
